import { render, staticRenderFns } from "./NotifyUsersActionView.vue?vue&type=template&id=fac648b4&scoped=true&"
import script from "./NotifyUsersActionView.vue?vue&type=script&lang=ts&"
export * from "./NotifyUsersActionView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fac648b4",
  null
  
)

export default component.exports